import React from 'react';

/* eslint-disable */
function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 496"
      xmlSpace="preserve"
    >
      <circle
        style={{
						  fill: '#383a39',
        }}
        cx={248}
        cy={24}
        r={24}
      />
      <circle
        style={{
						  fill: '#eceeee',
        }}
        cx={248}
        cy={472}
        r={24}
      />
      <circle
        style={{
						  fill: '#77807f',
        }}
        cx={136}
        cy={53.6}
        r={24}
      />
      <path
        style={{
						  fill: '#f2f4f4',
        }}
        d="M380.8 430.4c6.4 11.2 2.4 25.6-8.8 32.8-11.2 6.4-25.6 2.4-32.8-8.8-6.4-11.2-2.4-25.6 8.8-32.8 11.2-6.4 26.4-2.4 32.8 8.8z"
      />
      <path
        style={{
						  fill: '#9faaa9',
        }}
        d="M65.6 115.2c11.2 6.4 15.2 20.8 8.8 32.8-6.4 11.2-20.8 15.2-32.8 8.8-11.2-6.4-15.2-20.8-8.8-32.8s21.6-15.2 32.8-8.8z"
      />
      <path
        style={{
						  fill: '#f2f7f7',
        }}
        d="M454.4 339.2c11.2 6.4 15.2 20.8 8.8 32.8-6.4 11.2-20.8 15.2-32.8 8.8-11.2-6.4-15.2-20.8-8.8-32.8 6.4-11.2 20.8-15.2 32.8-8.8z"
      />
      <circle
        style={{
						  fill: '#b2bbba',
        }}
        cx={24}
        cy={248}
        r={24}
      />
      <circle
        style={{
						  fill: '#fff',
        }}
        cx={472}
        cy={248}
        r={24}
      />
      <path
        style={{
						  fill: '#c5cccb',
        }}
        d="M41.6 339.2c11.2-6.4 25.6-2.4 32.8 8.8 6.4 11.2 2.4 25.6-8.8 32.8-11.2 6.4-25.6 2.4-32.8-8.8s-2.4-25.6 8.8-32.8z"
      />
      <path d="M430.4 115.2c11.2-6.4 25.6-2.4 32.8 8.8 6.4 11.2 2.4 25.6-8.8 32.8-11.2 6.4-25.6 2.4-32.8-8.8-6.4-11.2-2.4-26.4 8.8-32.8z" />
      <path
        style={{
						  fill: '#d9dddd',
        }}
        d="M115.2 430.4c6.4-11.2 20.8-15.2 32.8-8.8 11.2 6.4 15.2 20.8 8.8 32.8-6.4 11.2-20.8 15.2-32.8 8.8-11.2-7.2-15.2-21.6-8.8-32.8z"
      />
      <path
        style={{
						  fill: '#111',
        }}
        d="M339.2 41.6c6.4-11.2 20.8-15.2 32.8-8.8 11.2 6.4 15.2 20.8 8.8 32.8-6.4 11.2-20.8 15.2-32.8 8.8-11.2-6.4-15.2-20.8-8.8-32.8z"
      />
    </svg>
  );
}

export default Loading;
